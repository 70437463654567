import * as React from 'react';
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Link } from '@mui/material';
import { VerifiedBadge } from "../components";




export default function MiniProductInfo({ sku }) {
  const navigate = useNavigate();
  const [productImage, setProductImage] = useState(<></>)

  useEffect(() => {
    let img = new Image();
    img.src = sku.images[0].url_ds;

    img.onload = () => {
      const relative_width = img.width / 270;
      const relative_height = img.height / 210;

      let style_obj = {
        maxHeight: "180px",
        width: "240px",
        height: "auto",
        margin: "auto"
      };
      if (relative_height > relative_width) {
        style_obj = {
          maxWidth: "240px",
          height: "180px",
          width: "auto",
          margin: "auto"
        };
      }

      setProductImage(
        <Box sx={{
          display: "flex",
          maxHeight: "180px",
          minHeight: "180px",
          minWidth: "240px",
          maxWidth: "240px",
          marginX: "8px",
          marginY: "16px"
        }}>
          <img 
            src={sku.images[0].url_ds}
            style={style_obj}
          />
        </Box>
      );
    };

    
  }, [sku]);


  if (!sku) {
    return(
      <></>
    );
  };

  return (
    <Link
      underline="hover"
      onClick={() => navigate(`/produto/${sku["id_sku"]}`, { state: { sku: sku } })}
      component={Box}
      sx={{
        "margin": "auto",
        "display": "flex",
        "flexDirection": "column",
        "alignItems": "center",
        // "justifyContent": "space-between",
        "width": 290,
        "height": 370,
        "borderRadius": "16px",
        "border": 1,
        "borderColor": "#F4F4F4",
        "cursor": "pointer"
      }}
    >

      {productImage}

      <Box sx={{ 
        marginY: "8px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        height: "150px",
        width: "265px",
       }}>

        <Box sx={{
          "fontSize": "20px",
          "fontWeight": "bold",
          "color": "#303742",
          "height": "2.7em",
          overflow: "hidden",
          width: "225px"
        }}>
          {sku.name}
        </Box>

        <VerifiedBadge verified={sku.verified} />

        <Box sx={{
          "fontSize": "13px",
          "fontWeight": "bold",
          "color": "#767F8D"
        }}>
          GTIN/EAN: {sku.ean[0]}
        </Box>

      </Box>

    </Link>
  );
}