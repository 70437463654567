import { useState } from 'react';
import {
  Box,
  Button,
  TextField,
  Checkbox,
  FormControlLabel
} from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import theme from '../helpers/theme';

import { createReport } from '../api';




export default function ReportButton({ token, id_sku }) {
  const [motive, setMotive] = useState("");
  const [disabled, setDisabled] = useState(false);


  const handleSubmit = async () => {
    let response = await createReport(id_sku, token, motive);

    if (response) {
      console.log(response);
      setDisabled(true);
    }
  };


  return (
    <Box sx={{ display: "flex", minWidth: "450px", height: "50px", marginY: "45px" }}>
      <Box
        sx={{
          "border": 1,
          "borderRadius": "200px",
          "borderColor": "#BBBFC6",
          "alignItems": "center",
          "justifyContent": "center",
          "width": "460px",
          "heigth": "50px",
          "paddingX": "16px"
        }}
      >
      <TextField 
        placeholder='Qual informação está errada?'
        fullWidth
        id="fullWidth"
        onChange={ e => setMotive(e.target.value) }
        variant="standard"
        InputProps={{
          disableUnderline: true,
          style: { fontSize: 16 },
        }}
        sx={{
          "marginTop": "9px"
        }}
      />
      </Box>
      

      <ThemeProvider theme={theme}>
        <Button
          variant="contained"
          disableRipple
          disabled={disabled}
          onClick={handleSubmit}
          color="red"
          sx={{
            "fontSize": 16,
            "borderRadius": "200px",
            "width": "140px",
            "marginLeft": "32px",
            "textTransform": "capitalize",
          }}
        >
          Reportar
        </Button>
      </ThemeProvider>
    </Box>
  )
}