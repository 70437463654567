import { useNavigate } from 'react-router-dom';
import { Link } from '@mui/material';
import logo from "../logoPortal.png";


export default function Logo() {
  const navigate = useNavigate();

  return (
    <Link
      onClick={() => navigate("/")}
      sx={{cursor: "pointer"}}
    >
      <img src={logo} style={{height: 60}} />
    </Link>
  );
}