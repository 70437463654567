import React  from 'react';
import { Box } from '@mui/material';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

export default function ImageCarousel({ images }) {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1
  };

  const formatted = images.map(i => {
    let aux = i.url_ds.split("/");

    if (
      aux.includes("extraLarge") ||
      aux.includes("extralarge") ||
      aux.includes("large") ||
      aux.includes("big")
    ) {
      return (
        <div key={i.url_ds}>
          <img src={i.url_ds} style={{ height: "280px", margin: "auto" }} />
        </div>
      );
    }
  });

  return (
    <Box sx={{
      // display: "flex",
      // adjustContent: "center",
      // height: "500px",
      // width: "500px"
    }}>
    <Box sx={{ height: "180px", width: "400px" }}>
      <Slider {...settings} >
        {formatted}
      </Slider> 
    </Box>
    </Box>
  );
}