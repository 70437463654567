import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';

import Keycloak from 'keycloak-js';


const keycloak = new Keycloak({
  "clientId": "portal",
  "realm": "nodis-catalog",
  "url": "https://sso.nodis.com.br/auth/",
  "sslRequired": "external",
  "resource": "portal",
  "publicClient": true,
  "verifyTokenAudience": true,
  "useResourceRoleMappings": true,
  "confidentialPort": 0
});

try {
  const authenticated = await keycloak.init({onLoad: "login-required"});
  console.log(`User is ${authenticated ? 'authenticated' : 'not authenticated'}`);
} catch (error) {
  console.error('Failed to initialize adapter:', error);
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App auth={keycloak} />
  </React.StrictMode>
);