import CircularProgress from '@mui/material/CircularProgress';


export default function LoadingCircle() {
  return (
    <CircularProgress sx={{
      "marginY": "24px",
      // "height": "88px",
      "color": '#5b2e90',
    }} />
  );
}