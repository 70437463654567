import React, { useState } from 'react';
import {
  Box,
} from '@mui/material';

import {
  LoadingCircle,
  SearchBar,
  SearchError,
  Logo,
  FiscalForm
} from '../components';


export default function Search({ auth }) {
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  return (
    <Box 
      sx={{
        "display": "flex",
        "flexDirection": "column",
        "alignItems": "center",
        "justifyContent": "center",
        "marginY": "40px",
        "marginX": "80px",
        "color": "#303742",
        "fontWeight": "bold",
      }}
    >
      <Box sx={{ marginRight: "auto", marginBottom: "40px" }}>
        <Logo />
      </Box>

      <Box sx={{
        "marginTop": "40px",
        "display": "flex",
        "flexDirection": "column",
        "alignItems": "center",
        "fontSize": "56px",
      }}>
        <div>O melhor catálogo de produtos</div>
        <div>para o seu negócio</div>
      </Box>

      <Box
        sx={{
          "display": "flex",
          "flexDirection": "column",
          "alignItems": "center",
          "justifyContent": "space-between",
          "marginTop": "80px",
        }}
      >
        <Box sx={{ marginBottom: "40px" }}>
          <SearchBar
            token={auth.token}
            setLoading={setLoading}
            setError={setError}
          />
        </Box>
        
        {error && !loading? <SearchError message={error} /> : null}

        {loading? <LoadingCircle /> : null}

      </Box>
    </Box>
  );
}