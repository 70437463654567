export default async function createReport(id_sku, token, motive = "") {
  const params = {
    cnpj: 54517628000198,
    motive: motive,
    id: id_sku,
  }
  
  const response = await fetch(
    process.env.REACT_APP_ELASTIC_API_URL
    + "/report/v1/create_report?"
    + new URLSearchParams(params),
    {
      method: "POST",
      mode: "cors",
      headers: {
        accept: "application/json",
        Authorization: `Bearer ${token}`
      }
    }
  )
  .then(response => response.json())
  .catch(error => {
    console.log(error);
  })

  return response;
}
