

export default async function getSkus(id_scroll, token) {
  if (!id_scroll || id_scroll === "") {
    return null;
  }

  let params = {
    cnpj: 54517628000198,
  }

  const response = await fetch(
    process.env.REACT_APP_ELASTIC_API_URL
    + `/search/v2/scroll/${id_scroll}?`
    + new URLSearchParams(params),
    {
      mode: "cors",
      headers: {
        accept: "application/json",
        Authorization: `Bearer ${token}`
      }
    }
  )
  .then(response => response.json())
  .catch(error => {
    console.log(error);
  })

  return response;
}
