import * as React from 'react';
import { useState, useEffect } from 'react';
import { Box } from '@mui/material';
import { GppBadOutlined, GppGoodOutlined } from '@mui/icons-material';


export default function VerifiedBadge({ verified, large=false }) {
  const [badge, setBadge] = useState(<></>);
  const [size, setSize] = useState("small");
  const [fontSize, setFontSize] = useState("13px");

  useEffect(() => {
    if (large) {
      setSize("medium");
      setFontSize("20px");
    }
  }, [large]);  

  useEffect(() => {
    if (verified) {
      setBadge(
        <Box sx={{
          display: "flex",
          alignItems: "center",
          "fontSize": fontSize,
          "color": "#767F8D"
        }}>
          <GppGoodOutlined
            fontSize={size}
            sx={{color: "green", marginRight: "4px"}}
          />
          Verificado
        </Box>
      );
    }
    else {
      setBadge(
        <Box sx={{
          display: "flex",
          alignItems: "center",
          "fontSize": fontSize,
          "color": "#767F8D"
        }}>
          <GppBadOutlined
            fontSize={size}
            sx={{color: "red", marginRight: "4px"}}
          />
          Não Verificado
        </Box>
      );
    }
  }, [verified, size, fontSize]);

  return (badge);
}