import { useState } from 'react';
import { getProductInfo } from '../api';
import { useNavigate } from 'react-router-dom';

import {
  Box,
  Button,
  TextField,
  InputAdornment,
  Checkbox,
  FormControlLabel
} from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import SearchIcon from '@mui/icons-material/Search';

import theme from '../helpers/theme';


function validateEan(ean) {
  return !isNaN(parseFloat(ean));
}


export default function SearchBar({ setLoading, setError, token }) {
  const [search, setSearch] = useState("")
  const [verified, setVerified] = useState(true);

  const navigate = useNavigate();

  const handleSubmit = async () => {
    if (search === "") {return}

    setLoading(true);
    let response = await getProductInfo(search, token, verified);
    setLoading(false);

    console.log(response);

    if (!response) {
      setError("EAN inserido é invalido");
      return;
    }
    if (response.skus.length === 0) {
      setError("O produto não foi encontrado. No entanto ele foi adicionado em nossa fila prioritária, e, caso seja um produto válido, em breve estará disponível em nosso catálogo.");
      return;
    }

    if (response.skus.length > 1) {
      console.log("multiple");
      navigate(`/busca`, {
        state: {
          skus: response.skus,
          scroll: response.id_scroll
        }
      });
    }
    else {
      navigate(`/produto/${response.skus[0]["id_sku"]}`, {
        state: {
          sku: response.skus[0],
        }
      });
    }
  };

  return (
    <Box sx={{
      display: "flex",
      flexDirection: "column",
      // justifyContent: "center",
      alignItems: "center",
    }}>
    <Box
      sx={{
        "display": "flex",
        "height": "50px"
      }}
    >

    <Box
      component="form"
      onSubmit={event => {
        event.preventDefault();
        handleSubmit();
      }}
      noValidate
      autoComplete="off"
      sx={{
        "border": 1,
        "borderRadius": "200px",
        "borderColor": "#BBBFC6",
        "alignItems": "center",
        "justifyContent": "center",
        "width": "460px",
        "heigth": "50px",
        "paddingX": "16px"
      }}
    >

      <TextField 
        placeholder='Pesquise o produto pelo seu nome ou EAN'
        fullWidth
        
        id="fullWidth"
        onChange={ e => setSearch(e.target.value) }
        variant="standard"
        InputProps={{
          disableUnderline: true,
          style: { fontSize: 16 },
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          ),
        }}
        sx={{
          "marginTop": "9px"
        }}
      />

    </Box>

    <ThemeProvider theme={theme}>
      <Button
        variant="contained"
        disableRipple
        onClick={handleSubmit}
        color="primary"
        sx={{
          "fontSize": 16,
          "borderRadius": "200px",
          "width": "120px",
          "marginLeft": "32px",
          "textTransform": "capitalize",
        }}
      >
        Pesquisar
      </Button>
    </ThemeProvider>
    </Box>

    <Box sx={{
      color: "#767F8D",
      marginRight: "auto",
      marginLeft: "20px",
    }}>
      <FormControlLabel
        label="Somente produtos verificados"
        control={
          <Checkbox 
            defaultChecked
            onChange={() => setVerified(prev => !prev)}
            sx = {{
              color: "#5b2e90",
              '&.Mui-checked': {
                color: "#5b2e90",
              },
            }}
          />
        }
      />
    </Box>

    </Box>
  );
}