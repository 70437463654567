import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import { useLocation, useParams, useNavigate } from 'react-router-dom';
import { getFiscal, getProductById } from '../api';
import {
  ProductInfo,
  Header,
  SearchError,
  LoadingCircle,
  ReportButton
} from '../components';


export default function Product({ auth }) {
  const [data, setData] = useState(null);
  const [fiscal, setFiscal] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);


  const location = useLocation();
  const navigate = useNavigate();
  const { id_sku } = useParams();


  useEffect(() => {
    if (location.state?.sku) {
      setData(location.state.sku);
    }

    else if (id_sku) {
      const fetchData = async () => {
        setLoading(true);
        const response = await getProductById(id_sku, auth.token);

        if (response) {
          console.log(response);
          setData(response);
          setLoading(false);
        }
      };
      fetchData();
    }

    else  {
      navigate("/");
    }
  }, [location.state, navigate, id_sku, auth])


  useEffect(() => {
    if (data) {
      const fetchData = async () => {
        const response = await getFiscal(data.ean[0], auth.token);
  
        if (response) {
          console.log(response.dados[0]);
          setFiscal(response.dados[0]);
        }
      };
      fetchData();
    }
  }, [data, auth])


  return(
    <Box sx={{
      "display": "flex",
      "flexDirection": "column",
      "alignItems": "center",
      "justifyContent": "center",
      "marginY": "40px",
      "marginX": "80px",
    }}>
      <Header
        token={auth.token}
        setLoading={setLoading}
        setError={setError}
      />

      <Box sx={{
        display: "flex",
        flexDirection: "column",
        width:"100%",
        maxWidth: "1300px"
      }}>

        {!loading && !error? <ProductInfo sku={data} fiscalData={fiscal} /> : null}

        {!loading && !error? <ReportButton token={auth.token} id_sku={id_sku} /> : null}
        
      </Box>
      
      {error && !loading? <SearchError message={error} /> : null}

      {loading? <LoadingCircle /> : null}



    </Box>
  );
}