

export default function(fieldName) {
  let result = null;

  switch (fieldName) {
    case "cstEnt":
      result = "CST de entrada";
      break;

    case "cstSai":
      result = "CST de saída";
      break;

    case "aliqPis":
      result = "Alíquota PIS";
      break;

    case "aliqCofins":
      result = "Alíquota COFINS";
      break;

    case "ampLegal":
      result = "Amparo legal";
      break;

    case "redPis":
      result = "Redução PIS";
      break;

    case "redCofins":
      result = "Redução COFINS";
      break;
    
    case "aliqipi":
      result = "Alíquota IPI";
      break;
      
    case "codenq":
      result = "Código de enquadramento";
      break;

    default:
      result = null;
  }

  return result;
}