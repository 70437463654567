import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Box } from '@mui/material';

import { getScroll } from '../api';
import {
  ProductGrid,
  Header,
  SearchError,
  LoadingCircle,
  Logo
} from '../components';


export default function SearchResults({ auth }) {
  const [data, setData] = useState(null);
  const [scroll, setScroll] = useState(null);
  const [loading, setLoading] = useState(false);
  const [loadScroll, setLoadScroll] = useState(false);
  const [hasScrolled, setHasScrolled] = useState(false);
  const [error, setError] = useState(null);
  const [page, setPage] = useState(0);
  const [prevPage, setPrevPage] = useState(0);

  const location = useLocation();
  const navigate = useNavigate();


  useEffect(() => {
    console.log(location.state);
    if (location.state?.skus) {
      setData(location.state.skus);

      if (location.state.scroll) {
        setScroll(location.state.scroll);
      }
    }
    else  {
      console.log("navigating back");
      navigate("/");
    }
  }, [location.state, navigate]);


  useEffect(() => {
    if (!hasScrolled || loadScroll || !scroll) return;

    if (page === prevPage) return;

    // console.log(loadScroll);

    const fetchScroll = async () => {
      setLoadScroll(true);
      const response = await getScroll(scroll, auth.token);
      if (response) {
        setData(prev => [...prev, ...response.skus]);
      }
      setPrevPage(page);
      setLoadScroll(false);
    };

    fetchScroll();
  }, [page, hasScrolled, loadScroll, scroll, auth]);


  const handleScroll = () => {
    if (
      window.innerHeight + document.documentElement.scrollTop ===
      document.documentElement.offsetHeight && !loadScroll
    ) {
      setPage(prev => prev + 1);
    }
    setHasScrolled(true);
  };


  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [loadScroll])


  return (
    <Box sx={{
      "display": "flex",
      "flexDirection": "column",
      "alignItems": "center",
      "justifyContent": "center",
      "marginY": "40px",
      "marginX": "80px",
      // backgroundColor: "blue"
    }}>
      <Header
        token={auth.token}
        setLoading={setLoading}
        setError={setError}
      />

      {error && !loading? <SearchError message={error} /> : null}

      {loading ? <LoadingCircle /> : null}

      {(!error && !loading)? <ProductGrid skus={data} /> : null}

      {loadScroll ? <LoadingCircle /> : null}

    </Box>
  );
}
