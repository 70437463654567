


export default async function getSkus(searchTerm, token, verified) {
  if (searchTerm === "") {
    return null;
  }


  let params = {};
  if (!isNaN(Number(searchTerm))) {
    params = {
      cnpj: 54517628000198,
      ean: searchTerm,
      verificados: verified
    }
  }
  else {
    params = {
      cnpj: 54517628000198,
      termo: searchTerm,
      quantidade: 18,
      verificados: verified
    }
  }
  
  

  const response = await fetch(
    process.env.REACT_APP_ELASTIC_API_URL
    + "/search/v2/sku?"
    + new URLSearchParams(params),
    {
      mode: "cors",
      headers: {
        accept: "application/json",
        Authorization: `Bearer ${token}`
      }
    }
  )
  .then(response => response.json())
  .catch(error => {
    console.log(error);
  })

  return response;
}
