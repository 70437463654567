import { Box } from '@mui/material';
import {
  Logo,
  SearchBar
} from "../components";



export default function Header({ token, setLoading, setError, verified }) {
  return (
    <Box sx={{
      "width": "100%",
      "display": "flex",
      "flexDirection": "row",
      "alignItems": "flex-start",
      "justifyContent": "space-between",
      marginBottom: "40px"
    }}>
      <Logo />
      <SearchBar
        token={token}
        setLoading={setLoading}
        setError={setError}
        verified={verified}
      />
    </Box>
  );
}