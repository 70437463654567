export default async function getSkuById(id_sku, token) {
  const response = await fetch(
    process.env.REACT_APP_ELASTIC_API_URL
    + `/search/v2/sku/${id_sku}?`
    + new URLSearchParams({
      cnpj: 54517628000198
    }),
    {
      mode: "cors",
      headers: {
        accept: "application/json",
        Authorization: `Bearer ${token}`
      }
    }
  )
  .then(response => response.json())
  .catch(error => {
    console.log(error);
  })

  return response;
}
