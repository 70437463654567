

export default async function GetFiscal(ean, token) {
  let params = {
    cnpj: 54517628000198,
    eans: [ean],
    uf: "RJ"
  };

  const response = await fetch(
    process.env.REACT_APP_ELASTIC_API_URL
    + "/fiscal/v1/dadosFiscais?"
    + new URLSearchParams(params),
    {
      mode: "cors",
      headers: {
        accept: "application/json",
        Authorization: `Bearer ${token}`
      }
    }
  )
  .then(response => response.json())
  .catch(error => {
    console.log(error);
  })

  return response;
}