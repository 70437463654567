import { useState } from 'react';
import {
  Box,
  Alert,
  IconButton,
  Collapse
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';


export default function SearchError({ message }) {
  const [open, setOpen] = useState(true);

  return(
    <Collapse 
      in={open}
      
    >
    <Alert
      action={
        <IconButton
          aria-label="close"
          color="inherit"
          size="small"
          onClick={() => setOpen(false)}
        >
          <CloseIcon fontSize="inherit" />
        </IconButton>
      }
      severity="error"
      sx={{
        "borderRadius": "20px",
        "paddingRight": "20px",
        "minHeight": "50px",
        "maxWidth": "500px",
        // "marginY": "50px"
      }}
    >
      {message}
    </Alert>
    </Collapse>
  );
}