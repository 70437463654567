import './App.css';
import React, { useState, useEffect } from 'react';
import {
  createBrowserRouter,
  RouterProvider
} from "react-router-dom";

import {
  Search,
  Product,
  SearchResults,
} from './pages';


function App({ auth }) {
  const router = createBrowserRouter([
    {
      path: "/",
      element: <Search auth={auth} />
    },
    {
      path: "produto/:id_sku",
      element: <Product auth={auth} />
    },
    {
      path: "busca/",
      element: <SearchResults auth={auth} />
    }
  ]);

  return (
    <RouterProvider router={router} />
  );
}

export default App;
