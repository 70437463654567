import * as React from 'react';
import { Grid, Box } from '@mui/material';

import MiniProductInfo from './MiniProductInfo';



export default function ProductGrid({ skus }) {
  const gridItems = skus?.map(i => 
      <Grid item xs={4}>
        <MiniProductInfo sku={i} />
      </Grid>
  );

  return (skus ? (
    <Grid container spacing={2} sx={{ width: "940px", marginTop: "30px" }}>
      {gridItems}
    </Grid>
  ) : null);
}