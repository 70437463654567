import * as React from 'react';
import {
  Box,
  Typography
} from '@mui/material';
import {
  VerifiedBadge,
  ImageCarousel,
  ReportButton
} from "../components";
import { fiscalTranslator } from '../helpers';


export default function ProductInfo({ sku, fiscalData }) {
  if (!sku) {
    return(
      <></>
    );
  };

  let date = new Date(Date.parse(sku.updated_at));

  const attributes = (
    <Box sx={{ "fontWeight": "normal", }}>
    <ol style={{ listStyleType: "inherit" }}>
      {sku.attributes.map(i =>
        <li style={{ marginBottom: "4px" }}>{i.name}: {i.value}</li>
      )}
    </ol>
    </Box>
  );

  const packageDimsensions = (
    <Box sx={{ "fontWeight": "normal", }}>
    <ol style={{ listStyleType: "inherit" }}>
      <li style={{ marginBottom: "4px" }}>Altura: {sku.package.height} cm</li>
      <li style={{ marginBottom: "4px" }}>Largura: {sku.package.width} cm</li>
      <li style={{ marginBottom: "4px" }}>Profundidade: {sku.package.depth} cm</li>
      <li style={{ marginBottom: "4px" }}>Peso: {sku.package.weight} kg</li>
    </ol>
    </Box>
  );


  return (
    <Box sx={{
      "display": "flex",
      "flexDirection": "column",
      "alignItems": "flex-start",
      "justifyContent": "center",
      "width": "100%",
      // maxWidth: "1300px",
      "color": "#303742",
      "fontSize": "16px",
      "wordBreak": "break-word",
      "fontWeight": "bold",
    }}>
      <Box sx={{
        "width": "100%",
        "display": "flex",
        "flexDirection": "row",
        "alignItems": "flex-start",
        "justifyContent": "space-between",
        "marginBottom": "112px"
      }}>
        <Box sx={{
          "display": "flex",
          "flexDirection": "column",
          "alignItems": "flex-start",
          "minWidth": "400px",
          "marginRight": "80px",
        }}>
          <Box sx={{
            "fontSize": "13px",
            "fontWeight": "normal",
            "marginBottom": "32px",
          }}>
            <div>{sku.category_nm}</div>
          </Box>

          <Box sx={{ "fontSize": "32px", "marginBottom": "40px", "color": "#5b2e90" }}>
            <div>{sku.name}</div>
          </Box>

          <Box sx={{ "fontSize": "20px", "marginBottom": "32px", }}>
            <div>GTIN/EAN: {sku.ean[0]}</div>
          </Box>

          <Box sx={{
            // display: "flex",
            marginBottom: "24px",
            // alignItems: "center",
          }}>
            <span style={{ "fontSize": "20px", marginRight: "12px"}}>
              NCM:
            </span>
            <span style={{"fontWeight": "normal", marginRight: "4px"}}>
              {fiscalData?.nCM}
            </span>
            <span style={{"fontWeight": "normal"}}>
              {" - " + fiscalData?.descricao}
            </span>
          </Box>
          

          <VerifiedBadge verified={sku.verified} large={true} /> 

        </Box>
        
        <Box sx={{
          "display": "flex",
          "flexDirection": "column",
          "alignItems": "flex-end",
        }}>
          <Box sx={{
            fontSize: "13px",
            fontWeight: "normal",
            color: "#5b2e90",
            marginBottom: "32px",
          }}>
            <div>Produto atualizado em
              {` ${date.getDate()}/${date.getMonth()}/${date.getFullYear()} às ${date.getHours()}:${String(date.getMinutes()).padStart(2, "0")}`}
            </div>
          </Box>

          <ImageCarousel images={sku.images} />

        </Box>


      </Box>

      <Box sx={{ "fontSize": "32px", "marginBottom": "24px", }}>
        <div>Informações do produto</div>
      </Box>

      <Box sx={{ "fontSize": "24px", "marginBottom": "24px", }}>
        <div>Descrição</div>
      </Box>

      <Box sx={{"fontWeight": "normal", "marginBottom": "24px",}}>
        <div>{sku.description}</div>
      </Box>

      <Box sx={{ "fontSize": "24px", "marginTop": "16px", }}>
        <div>Ficha Técnica</div>
      </Box>

      {attributes}

      <Box sx={{ "fontSize": "24px", "marginTop": "16px", }}>
        <div>Dimensões da embalagem</div>
      </Box>

      {packageDimsensions}

      {fiscalData &&
      <Box sx={{ "fontSize": "32px", "marginY": "24px", }}>
        <div>Informações Fiscais</div>
      </Box>}

      {fiscalData?.cEST &&
      <Box sx={{ "fontSize": "24px", "marginBottom": "24px", }}>
        <div>CEST: {fiscalData?.cEST}</div>
      </Box>}

      {fiscalData?.pisCofins && 
      <Box sx={{ "fontSize": "24px" }}>
        <div>PIS/COFINS</div>
      </Box>}
        
      {fiscalData?.pisCofins ?
      <ol style={{ listStyleType: "inherit", fontWeight: "normal" }}>
        {Object.keys(fiscalData?.pisCofins).map(i => {
          if (!fiscalTranslator(i)) return null;

          return (
            <li>
              {fiscalTranslator(i)}: {fiscalData?.pisCofins[i]}
            </li>
          );
        })}
      </ol> : null}
      
      
      {fiscalData?.iPI &&
      <Box sx={{ "fontSize": "24px" }}>
        <div>IPI</div>
      </Box>}

      {fiscalData?.iPI ?
      <ol style={{ listStyleType: "inherit", fontWeight: "normal" }}>
        {Object.keys(fiscalData?.iPI).map(i => {
          if (!fiscalTranslator(i)) return null;

          return (
            <li>
              {fiscalTranslator(i)}: {fiscalData?.iPI[i]}
            </li>
          );
        })}
      </ol> : null}
      
    </Box>
  )
}
