import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  status: {
    danger: '#e53e3e',
  },
  palette: {
    primary: {
      main: '#5b2e90',
      darker: '#5b2e90',
    },
    neutral: {
      main: '#64748B',
      contrastText: '#fff',
    },
    red: {
      main: "#ff0000",
      dark: "#d41313",
      contrastText: '#fff',
    }
  },
});

export default theme;